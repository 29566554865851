/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Videobot
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AccountCreate,
  AccountCurrentPlanRead,
  AccountCurrentUserRead,
  AccountDetailRead,
  AccountInviteCreate,
  AccountProfileRead,
  AccountProfileUpdate,
  AccountSimpleWithAppearanceRead,
  AccountUpdate,
  AnalyticDetailBotRead,
  AnalyticEventCreate,
  AnalyticEventRead,
  AnalyticReportBotRead,
  AnalyticsAccountTotalRead,
  AnalyticsDailyTotalRead,
  BodyUploadMediaCaptionByIdMediaMediaIdCaptionPut,
  BotCreate,
  BotImportParams,
  BotImportRead,
  BotRead,
  BotRevisionRead,
  BotRevisionStatusUpdate,
  BotSubmitForm,
  BotSubmitForm200,
  BotSubmitFormLegacy,
  BotSubmitFormLegacy200,
  BotUpdate,
  BotWidgetDashboardRead,
  BotWidgetUpdate,
  BotWidgetUpdateResponse,
  CFBotAnalyticRead,
  CaptionBase,
  CreateFlag200,
  CreateMediaTus200,
  DeleteMediaCaptionById200,
  DeleteMediaCaptionByIdParams,
  FlagSchema,
  FlagUpdate,
  GetAllFeatureFlagsParams,
  GetAllMediaParams,
  GetCfBotAnalyticsParams,
  GetFeatureFlagsForCurrentUserParams,
  GetLegacyInfo200,
  GlobalConfig,
  HTTPValidationError,
  Healthcheck200,
  HubspotAuth,
  IntegrationRead,
  LinearAuth,
  MediaCreate,
  MediaCreateRead,
  MediaReadOutput,
  MediaUpdate,
  MediaWebhook200,
  PaginationAccountListRead,
  PaginationAccountSimpleRead,
  PaginationAnalyticsRead,
  PaginationAnalyticsWidgetRead,
  PaginationBotEmbedRead,
  PaginationBotListSingleRead,
  PaginationMediaRead,
  PaginationTagSchema,
  PaginationUserInAccountRead,
  PaginationWidgetRead,
  PublicEmbed,
  ReadAccountDailyTotalAnalyticsParams,
  ReadAccountTotalAnalyticsParams,
  ReadAccountsDetailParams,
  ReadAccountsParams,
  ReadAllBotEmbedsParams,
  ReadAllBotsParams,
  ReadAllTagsParams,
  ReadAllWidgetsParams,
  ReadAnalyticReportParams,
  ReadBotByIdParams,
  ReadBotEmbedByPublicId200,
  ReadBotWidgetByIdParams,
  ReadBotWidgetEmbedByPublicId200,
  ReadDetailBotAnalyticParams,
  ReadPaginateBotAnalyticsParams,
  ReadPaginatedWidgetAnalyticsParams,
  ReadUsersForAccountByIdParams,
  ResellerInviteCreate,
  RoleAccountUpdate,
  RoleDetailRead,
  RoleRead,
  RoleUpdate,
  TagCreate,
  TagSchema,
  UpdateFeature200,
  UpdateMediaByIdParams,
  UploadMediaCaptionByIdParams,
  UserAdminUpdate,
  UserFinalize,
  UserInAccountRead,
  UserRead,
  UserUpdate,
  UserWithRolesRead,
  WidgetCreate,
  WidgetEmbedRead,
  WidgetRead,
  WidgetUpdate
} from './videobot.schemas'
import { videobotFetch } from '../fetcher';
import type { ErrorType, BodyType } from '../fetcher';



/**
 * @summary Get Global Config
 */
export const getGlobalConfig = (
    
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<GlobalConfig>(
      {url: `/global`, method: 'GET', signal
    },
      );
    }
  

export const getGetGlobalConfigQueryKey = () => {
    return ['global'] as const;
    }

    
export const getGetGlobalConfigQueryOptions = <TData = Awaited<ReturnType<typeof getGlobalConfig>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGlobalConfig>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGlobalConfigQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGlobalConfig>>> = ({ signal }) => getGlobalConfig(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGlobalConfig>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGlobalConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getGlobalConfig>>>
export type GetGlobalConfigQueryError = ErrorType<unknown>

/**
 * @summary Get Global Config
 */
export const useGetGlobalConfig = <TData = Awaited<ReturnType<typeof getGlobalConfig>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGlobalConfig>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetGlobalConfigQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get all users.

Permission: Only superadmin.
 * @summary Get All Users
 */
export const getAllUsers = (
    
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<UserRead[]>(
      {url: `/user/all-users`, method: 'GET', signal
    },
      );
    }
  

export const getGetAllUsersQueryKey = () => {
    return ['user','all-users'] as const;
    }

    
export const getGetAllUsersQueryOptions = <TData = Awaited<ReturnType<typeof getAllUsers>>, TError = ErrorType<void>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllUsersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUsers>>> = ({ signal }) => getAllUsers(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getAllUsers>>>
export type GetAllUsersQueryError = ErrorType<void>

/**
 * @summary Get All Users
 */
export const useGetAllUsers = <TData = Awaited<ReturnType<typeof getAllUsers>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllUsersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get all resellers.

Permission: Only superadmin.
 * @summary Users:Resellers
 */
export const usersResellers = (
    
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<UserRead[]>(
      {url: `/user/resellers`, method: 'GET', signal
    },
      );
    }
  

export const getUsersResellersQueryKey = () => {
    return ['user','resellers'] as const;
    }

    
export const getUsersResellersQueryOptions = <TData = Awaited<ReturnType<typeof usersResellers>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersResellers>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersResellersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersResellers>>> = ({ signal }) => usersResellers(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof usersResellers>>, TError, TData> & { queryKey: QueryKey }
}

export type UsersResellersQueryResult = NonNullable<Awaited<ReturnType<typeof usersResellers>>>
export type UsersResellersQueryError = ErrorType<unknown>

/**
 * @summary Users:Resellers
 */
export const useUsersResellers = <TData = Awaited<ReturnType<typeof usersResellers>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersResellers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUsersResellersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create reseller.

Permission: Only superadmin.
 * @summary Create Reseller
 */
export const createReseller = (
    resellerInviteCreate: BodyType<ResellerInviteCreate>,
 ) => {
      
      
      return videobotFetch<UserRead>(
      {url: `/user/resellers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resellerInviteCreate
    },
      );
    }
  


export const getCreateResellerMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createReseller>>, TError,{data: BodyType<ResellerInviteCreate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createReseller>>, TError,{data: BodyType<ResellerInviteCreate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createReseller>>, {data: BodyType<ResellerInviteCreate>}> = (props) => {
          const {data} = props ?? {};

          return  createReseller(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateResellerMutationResult = NonNullable<Awaited<ReturnType<typeof createReseller>>>
    export type CreateResellerMutationBody = BodyType<ResellerInviteCreate>
    export type CreateResellerMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Reseller
 */
export const useCreateReseller = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createReseller>>, TError,{data: BodyType<ResellerInviteCreate>}, TContext>, }
) => {

      const mutationOptions = getCreateResellerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete reseller.

Permission: Only superadmin.
 * @summary Delete Reseller
 */
export const deleteReseller = (
    userId: number,
 ) => {
      
      
      return videobotFetch<UserRead>(
      {url: `/user/resellers/${userId}`, method: 'POST'
    },
      );
    }
  


export const getDeleteResellerMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteReseller>>, TError,{userId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteReseller>>, TError,{userId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteReseller>>, {userId: number}> = (props) => {
          const {userId} = props ?? {};

          return  deleteReseller(userId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteResellerMutationResult = NonNullable<Awaited<ReturnType<typeof deleteReseller>>>
    
    export type DeleteResellerMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Delete Reseller
 */
export const useDeleteReseller = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteReseller>>, TError,{userId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteResellerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get current user info with roles.
 * @summary Users:Current User
 */
export const usersCurrentUser = (
    
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<UserWithRolesRead>(
      {url: `/user/me`, method: 'GET', signal
    },
      );
    }
  

export const getUsersCurrentUserQueryKey = () => {
    return ['user','me'] as const;
    }

    
export const getUsersCurrentUserQueryOptions = <TData = Awaited<ReturnType<typeof usersCurrentUser>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersCurrentUser>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersCurrentUserQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersCurrentUser>>> = ({ signal }) => usersCurrentUser(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof usersCurrentUser>>, TError, TData> & { queryKey: QueryKey }
}

export type UsersCurrentUserQueryResult = NonNullable<Awaited<ReturnType<typeof usersCurrentUser>>>
export type UsersCurrentUserQueryError = ErrorType<unknown>

/**
 * @summary Users:Current User
 */
export const useUsersCurrentUser = <TData = Awaited<ReturnType<typeof usersCurrentUser>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersCurrentUser>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUsersCurrentUserQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Users:Patch Current User
 */
export const usersPatchCurrentUser = (
    userUpdate: BodyType<UserUpdate>,
 ) => {
      
      
      return videobotFetch<UserRead>(
      {url: `/user/me`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: userUpdate
    },
      );
    }
  


export const getUsersPatchCurrentUserMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersPatchCurrentUser>>, TError,{data: BodyType<UserUpdate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof usersPatchCurrentUser>>, TError,{data: BodyType<UserUpdate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersPatchCurrentUser>>, {data: BodyType<UserUpdate>}> = (props) => {
          const {data} = props ?? {};

          return  usersPatchCurrentUser(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UsersPatchCurrentUserMutationResult = NonNullable<Awaited<ReturnType<typeof usersPatchCurrentUser>>>
    export type UsersPatchCurrentUserMutationBody = BodyType<UserUpdate>
    export type UsersPatchCurrentUserMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Users:Patch Current User
 */
export const useUsersPatchCurrentUser = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersPatchCurrentUser>>, TError,{data: BodyType<UserUpdate>}, TContext>, }
) => {

      const mutationOptions = getUsersPatchCurrentUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get current user's available accounts.

Superadmin: Returns all accounts.
 * @summary Read Current User Accounts
 */
export const readCurrentUserAccounts = (
    
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<AccountCurrentUserRead[]>(
      {url: `/user/me/account`, method: 'GET', signal
    },
      );
    }
  

export const getReadCurrentUserAccountsQueryKey = () => {
    return ['user','me','account'] as const;
    }

    
export const getReadCurrentUserAccountsQueryOptions = <TData = Awaited<ReturnType<typeof readCurrentUserAccounts>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readCurrentUserAccounts>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadCurrentUserAccountsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readCurrentUserAccounts>>> = ({ signal }) => readCurrentUserAccounts(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readCurrentUserAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadCurrentUserAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof readCurrentUserAccounts>>>
export type ReadCurrentUserAccountsQueryError = ErrorType<unknown>

/**
 * @summary Read Current User Accounts
 */
export const useReadCurrentUserAccounts = <TData = Awaited<ReturnType<typeof readCurrentUserAccounts>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readCurrentUserAccounts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadCurrentUserAccountsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get user by id.

Permission: Either superadmin, current user, or owner of an account that user in can read.
 * @summary Get User By Id
 */
export const getUserById = (
    userId: number,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<UserWithRolesRead>(
      {url: `/user/${userId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserByIdQueryKey = (userId: number,) => {
    return ['user',userId] as const;
    }

    
export const getGetUserByIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserById>>, TError = ErrorType<void | HTTPValidationError>>(userId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserByIdQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserById>>> = ({ signal }) => getUserById(userId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserById>>>
export type GetUserByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Get User By Id
 */
export const useGetUserById = <TData = Awaited<ReturnType<typeof getUserById>>, TError = ErrorType<void | HTTPValidationError>>(
 userId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserByIdQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Users:Patch User
 */
export const usersPatchUser = (
    userId: number,
    userAdminUpdate: BodyType<UserAdminUpdate>,
 ) => {
      
      
      return videobotFetch<UserRead>(
      {url: `/user/${userId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: userAdminUpdate
    },
      );
    }
  


export const getUsersPatchUserMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersPatchUser>>, TError,{userId: number;data: BodyType<UserAdminUpdate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof usersPatchUser>>, TError,{userId: number;data: BodyType<UserAdminUpdate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersPatchUser>>, {userId: number;data: BodyType<UserAdminUpdate>}> = (props) => {
          const {userId,data} = props ?? {};

          return  usersPatchUser(userId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UsersPatchUserMutationResult = NonNullable<Awaited<ReturnType<typeof usersPatchUser>>>
    export type UsersPatchUserMutationBody = BodyType<UserAdminUpdate>
    export type UsersPatchUserMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Users:Patch User
 */
export const useUsersPatchUser = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersPatchUser>>, TError,{userId: number;data: BodyType<UserAdminUpdate>}, TContext>, }
) => {

      const mutationOptions = getUsersPatchUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read users list for an account by id.
 * @summary Read Roles For User By Id
 */
export const readRolesForUserById = (
    userId: number,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<RoleDetailRead[]>(
      {url: `/user/${userId}/role`, method: 'GET', signal
    },
      );
    }
  

export const getReadRolesForUserByIdQueryKey = (userId: number,) => {
    return ['user',userId,'role'] as const;
    }

    
export const getReadRolesForUserByIdQueryOptions = <TData = Awaited<ReturnType<typeof readRolesForUserById>>, TError = ErrorType<void | HTTPValidationError>>(userId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readRolesForUserById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadRolesForUserByIdQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readRolesForUserById>>> = ({ signal }) => readRolesForUserById(userId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readRolesForUserById>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadRolesForUserByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readRolesForUserById>>>
export type ReadRolesForUserByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Roles For User By Id
 */
export const useReadRolesForUserById = <TData = Awaited<ReturnType<typeof readRolesForUserById>>, TError = ErrorType<void | HTTPValidationError>>(
 userId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readRolesForUserById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadRolesForUserByIdQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update role for user by id.
 * @summary Update Role For User By Id
 */
export const updateRoleForUserById = (
    userId: number,
    roleId: number,
    roleUpdate: BodyType<RoleUpdate>,
 ) => {
      
      
      return videobotFetch<RoleRead>(
      {url: `/user/${userId}/role/${roleId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: roleUpdate
    },
      );
    }
  


export const getUpdateRoleForUserByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRoleForUserById>>, TError,{userId: number;roleId: number;data: BodyType<RoleUpdate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateRoleForUserById>>, TError,{userId: number;roleId: number;data: BodyType<RoleUpdate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRoleForUserById>>, {userId: number;roleId: number;data: BodyType<RoleUpdate>}> = (props) => {
          const {userId,roleId,data} = props ?? {};

          return  updateRoleForUserById(userId,roleId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateRoleForUserByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateRoleForUserById>>>
    export type UpdateRoleForUserByIdMutationBody = BodyType<RoleUpdate>
    export type UpdateRoleForUserByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Update Role For User By Id
 */
export const useUpdateRoleForUserById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRoleForUserById>>, TError,{userId: number;roleId: number;data: BodyType<RoleUpdate>}, TContext>, }
) => {

      const mutationOptions = getUpdateRoleForUserByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Users:Delete User
 */
export const usersDeleteUser = (
    id: string,
 ) => {
      
      
      return videobotFetch<void>(
      {url: `/user/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getUsersDeleteUserMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersDeleteUser>>, TError,{id: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof usersDeleteUser>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersDeleteUser>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  usersDeleteUser(id,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UsersDeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof usersDeleteUser>>>
    
    export type UsersDeleteUserMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Users:Delete User
 */
export const useUsersDeleteUser = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersDeleteUser>>, TError,{id: string}, TContext>, }
) => {

      const mutationOptions = getUsersDeleteUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Finalize user profile and accept the invitation.
 * @summary Finalize User
 */
export const finalizeUser = (
    userFinalize: BodyType<UserFinalize>,
 ) => {
      
      
      return videobotFetch<UserRead>(
      {url: `/auth/finalize`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userFinalize
    },
      );
    }
  


export const getFinalizeUserMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof finalizeUser>>, TError,{data: BodyType<UserFinalize>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof finalizeUser>>, TError,{data: BodyType<UserFinalize>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof finalizeUser>>, {data: BodyType<UserFinalize>}> = (props) => {
          const {data} = props ?? {};

          return  finalizeUser(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type FinalizeUserMutationResult = NonNullable<Awaited<ReturnType<typeof finalizeUser>>>
    export type FinalizeUserMutationBody = BodyType<UserFinalize>
    export type FinalizeUserMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Finalize User
 */
export const useFinalizeUser = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof finalizeUser>>, TError,{data: BodyType<UserFinalize>}, TContext>, }
) => {

      const mutationOptions = getFinalizeUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read accounts, superadmin will get all accounts on filter Owned.

Default filtered by Owned.
 * @summary Read Accounts
 */
export const readAccounts = (
    params?: ReadAccountsParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<PaginationAccountSimpleRead>(
      {url: `/account`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadAccountsQueryKey = (params?: ReadAccountsParams,) => {
    return ['account',...(params ? [params]: [])] as const;
    }

    
export const getReadAccountsQueryOptions = <TData = Awaited<ReturnType<typeof readAccounts>>, TError = ErrorType<HTTPValidationError>>(params?: ReadAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccounts>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAccountsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAccounts>>> = ({ signal }) => readAccounts(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof readAccounts>>>
export type ReadAccountsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read Accounts
 */
export const useReadAccounts = <TData = Awaited<ReturnType<typeof readAccounts>>, TError = ErrorType<HTTPValidationError>>(
 params?: ReadAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccounts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAccountsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create new account.
 * @summary Create Account
 */
export const createAccount = (
    accountCreate: BodyType<AccountCreate>,
 ) => {
      
      
      return videobotFetch<AccountDetailRead>(
      {url: `/account`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: accountCreate
    },
      );
    }
  


export const getCreateAccountMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAccount>>, TError,{data: BodyType<AccountCreate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createAccount>>, TError,{data: BodyType<AccountCreate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAccount>>, {data: BodyType<AccountCreate>}> = (props) => {
          const {data} = props ?? {};

          return  createAccount(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateAccountMutationResult = NonNullable<Awaited<ReturnType<typeof createAccount>>>
    export type CreateAccountMutationBody = BodyType<AccountCreate>
    export type CreateAccountMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Account
 */
export const useCreateAccount = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAccount>>, TError,{data: BodyType<AccountCreate>}, TContext>, }
) => {

      const mutationOptions = getCreateAccountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read all accounts with details.

Allow: Super Admin, Reseller
 * @summary Read Accounts Detail
 */
export const readAccountsDetail = (
    params?: ReadAccountsDetailParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<PaginationAccountListRead>(
      {url: `/account/detail`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadAccountsDetailQueryKey = (params?: ReadAccountsDetailParams,) => {
    return ['account','detail',...(params ? [params]: [])] as const;
    }

    
export const getReadAccountsDetailQueryOptions = <TData = Awaited<ReturnType<typeof readAccountsDetail>>, TError = ErrorType<HTTPValidationError>>(params?: ReadAccountsDetailParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountsDetail>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAccountsDetailQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAccountsDetail>>> = ({ signal }) => readAccountsDetail(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAccountsDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAccountsDetailQueryResult = NonNullable<Awaited<ReturnType<typeof readAccountsDetail>>>
export type ReadAccountsDetailQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read Accounts Detail
 */
export const useReadAccountsDetail = <TData = Awaited<ReturnType<typeof readAccountsDetail>>, TError = ErrorType<HTTPValidationError>>(
 params?: ReadAccountsDetailParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountsDetail>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAccountsDetailQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Read one account by id.
 * @summary Read Account By Id
 */
export const readAccountById = (
    accountId: number,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<AccountSimpleWithAppearanceRead>(
      {url: `/account/${accountId}`, method: 'GET', signal
    },
      );
    }
  

export const getReadAccountByIdQueryKey = (accountId: number,) => {
    return ['account',accountId] as const;
    }

    
export const getReadAccountByIdQueryOptions = <TData = Awaited<ReturnType<typeof readAccountById>>, TError = ErrorType<void | HTTPValidationError>>(accountId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAccountByIdQueryKey(accountId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAccountById>>> = ({ signal }) => readAccountById(accountId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAccountById>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAccountByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readAccountById>>>
export type ReadAccountByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Account By Id
 */
export const useReadAccountById = <TData = Awaited<ReturnType<typeof readAccountById>>, TError = ErrorType<void | HTTPValidationError>>(
 accountId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAccountByIdQueryOptions(accountId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Account By Id
 */
export const updateAccountById = (
    accountId: number,
    accountUpdate: BodyType<AccountUpdate>,
 ) => {
      
      
      return videobotFetch<AccountDetailRead>(
      {url: `/account/${accountId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: accountUpdate
    },
      );
    }
  


export const getUpdateAccountByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAccountById>>, TError,{accountId: number;data: BodyType<AccountUpdate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAccountById>>, TError,{accountId: number;data: BodyType<AccountUpdate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAccountById>>, {accountId: number;data: BodyType<AccountUpdate>}> = (props) => {
          const {accountId,data} = props ?? {};

          return  updateAccountById(accountId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateAccountByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateAccountById>>>
    export type UpdateAccountByIdMutationBody = BodyType<AccountUpdate>
    export type UpdateAccountByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Update Account By Id
 */
export const useUpdateAccountById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAccountById>>, TError,{accountId: number;data: BodyType<AccountUpdate>}, TContext>, }
) => {

      const mutationOptions = getUpdateAccountByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Read Plan
 */
export const readPlan = (
    accountId: number,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<AccountCurrentPlanRead>(
      {url: `/account/${accountId}/plan`, method: 'GET', signal
    },
      );
    }
  

export const getReadPlanQueryKey = (accountId: number,) => {
    return ['account',accountId,'plan'] as const;
    }

    
export const getReadPlanQueryOptions = <TData = Awaited<ReturnType<typeof readPlan>>, TError = ErrorType<void | HTTPValidationError>>(accountId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readPlan>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadPlanQueryKey(accountId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readPlan>>> = ({ signal }) => readPlan(accountId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readPlan>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadPlanQueryResult = NonNullable<Awaited<ReturnType<typeof readPlan>>>
export type ReadPlanQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Plan
 */
export const useReadPlan = <TData = Awaited<ReturnType<typeof readPlan>>, TError = ErrorType<void | HTTPValidationError>>(
 accountId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readPlan>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadPlanQueryOptions(accountId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Read one account full detail by id.

Allows: Owner & Superadmin
 * @summary Read Account Detail By Id
 */
export const readAccountDetailById = (
    accountId: number,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<AccountDetailRead>(
      {url: `/account/${accountId}/detail`, method: 'GET', signal
    },
      );
    }
  

export const getReadAccountDetailByIdQueryKey = (accountId: number,) => {
    return ['account',accountId,'detail'] as const;
    }

    
export const getReadAccountDetailByIdQueryOptions = <TData = Awaited<ReturnType<typeof readAccountDetailById>>, TError = ErrorType<void | HTTPValidationError>>(accountId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountDetailById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAccountDetailByIdQueryKey(accountId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAccountDetailById>>> = ({ signal }) => readAccountDetailById(accountId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAccountDetailById>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAccountDetailByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readAccountDetailById>>>
export type ReadAccountDetailByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Account Detail By Id
 */
export const useReadAccountDetailById = <TData = Awaited<ReturnType<typeof readAccountDetailById>>, TError = ErrorType<void | HTTPValidationError>>(
 accountId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountDetailById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAccountDetailByIdQueryOptions(accountId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Read Account Profile By Id
 */
export const readAccountProfileById = (
    accountId: number,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<AccountProfileRead>(
      {url: `/account/${accountId}/profile`, method: 'GET', signal
    },
      );
    }
  

export const getReadAccountProfileByIdQueryKey = (accountId: number,) => {
    return ['account',accountId,'profile'] as const;
    }

    
export const getReadAccountProfileByIdQueryOptions = <TData = Awaited<ReturnType<typeof readAccountProfileById>>, TError = ErrorType<void | HTTPValidationError>>(accountId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountProfileById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAccountProfileByIdQueryKey(accountId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAccountProfileById>>> = ({ signal }) => readAccountProfileById(accountId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAccountProfileById>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAccountProfileByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readAccountProfileById>>>
export type ReadAccountProfileByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Account Profile By Id
 */
export const useReadAccountProfileById = <TData = Awaited<ReturnType<typeof readAccountProfileById>>, TError = ErrorType<void | HTTPValidationError>>(
 accountId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountProfileById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAccountProfileByIdQueryOptions(accountId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Account Profile By Id
 */
export const updateAccountProfileById = (
    accountId: number,
    accountProfileUpdate: BodyType<AccountProfileUpdate>,
 ) => {
      
      
      return videobotFetch<AccountProfileRead>(
      {url: `/account/${accountId}/profile`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: accountProfileUpdate
    },
      );
    }
  


export const getUpdateAccountProfileByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAccountProfileById>>, TError,{accountId: number;data: BodyType<AccountProfileUpdate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAccountProfileById>>, TError,{accountId: number;data: BodyType<AccountProfileUpdate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAccountProfileById>>, {accountId: number;data: BodyType<AccountProfileUpdate>}> = (props) => {
          const {accountId,data} = props ?? {};

          return  updateAccountProfileById(accountId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateAccountProfileByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateAccountProfileById>>>
    export type UpdateAccountProfileByIdMutationBody = BodyType<AccountProfileUpdate>
    export type UpdateAccountProfileByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Update Account Profile By Id
 */
export const useUpdateAccountProfileById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAccountProfileById>>, TError,{accountId: number;data: BodyType<AccountProfileUpdate>}, TContext>, }
) => {

      const mutationOptions = getUpdateAccountProfileByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read one account integrations by id.

Allows: Owner & Superadmin
 * @summary Read Account Integrations By Id
 */
export const readAccountIntegrationsById = (
    accountId: number,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<IntegrationRead[]>(
      {url: `/account/${accountId}/integration`, method: 'GET', signal
    },
      );
    }
  

export const getReadAccountIntegrationsByIdQueryKey = (accountId: number,) => {
    return ['account',accountId,'integration'] as const;
    }

    
export const getReadAccountIntegrationsByIdQueryOptions = <TData = Awaited<ReturnType<typeof readAccountIntegrationsById>>, TError = ErrorType<void | HTTPValidationError>>(accountId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountIntegrationsById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAccountIntegrationsByIdQueryKey(accountId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAccountIntegrationsById>>> = ({ signal }) => readAccountIntegrationsById(accountId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAccountIntegrationsById>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAccountIntegrationsByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readAccountIntegrationsById>>>
export type ReadAccountIntegrationsByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Account Integrations By Id
 */
export const useReadAccountIntegrationsById = <TData = Awaited<ReturnType<typeof readAccountIntegrationsById>>, TError = ErrorType<void | HTTPValidationError>>(
 accountId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountIntegrationsById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAccountIntegrationsByIdQueryOptions(accountId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Read users list for an account by id.
 * @summary Read Users For Account By Id
 */
export const readUsersForAccountById = (
    accountId: number,
    params?: ReadUsersForAccountByIdParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<PaginationUserInAccountRead>(
      {url: `/account/${accountId}/user`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadUsersForAccountByIdQueryKey = (accountId: number,
    params?: ReadUsersForAccountByIdParams,) => {
    return ['account',accountId,'user',...(params ? [params]: [])] as const;
    }

    
export const getReadUsersForAccountByIdQueryOptions = <TData = Awaited<ReturnType<typeof readUsersForAccountById>>, TError = ErrorType<void | HTTPValidationError>>(accountId: number,
    params?: ReadUsersForAccountByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readUsersForAccountById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadUsersForAccountByIdQueryKey(accountId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readUsersForAccountById>>> = ({ signal }) => readUsersForAccountById(accountId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readUsersForAccountById>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadUsersForAccountByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readUsersForAccountById>>>
export type ReadUsersForAccountByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Users For Account By Id
 */
export const useReadUsersForAccountById = <TData = Awaited<ReturnType<typeof readUsersForAccountById>>, TError = ErrorType<void | HTTPValidationError>>(
 accountId: number,
    params?: ReadUsersForAccountByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readUsersForAccountById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadUsersForAccountByIdQueryOptions(accountId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Invite an user to an account by id, create a new user with given email if necessary.

Forbidden on suspended.
 * @summary Invite User To Account By Id
 */
export const inviteUserToAccountById = (
    accountId: number,
    accountInviteCreate: BodyType<AccountInviteCreate>,
 ) => {
      
      
      return videobotFetch<UserRead>(
      {url: `/account/${accountId}/user`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: accountInviteCreate
    },
      );
    }
  


export const getInviteUserToAccountByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inviteUserToAccountById>>, TError,{accountId: number;data: BodyType<AccountInviteCreate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof inviteUserToAccountById>>, TError,{accountId: number;data: BodyType<AccountInviteCreate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inviteUserToAccountById>>, {accountId: number;data: BodyType<AccountInviteCreate>}> = (props) => {
          const {accountId,data} = props ?? {};

          return  inviteUserToAccountById(accountId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type InviteUserToAccountByIdMutationResult = NonNullable<Awaited<ReturnType<typeof inviteUserToAccountById>>>
    export type InviteUserToAccountByIdMutationBody = BodyType<AccountInviteCreate>
    export type InviteUserToAccountByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Invite User To Account By Id
 */
export const useInviteUserToAccountById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inviteUserToAccountById>>, TError,{accountId: number;data: BodyType<AccountInviteCreate>}, TContext>, }
) => {

      const mutationOptions = getInviteUserToAccountByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete an user role from an account.

Allows: Owner, Superadmin
Forbidden on suspended.
 * @summary Delete User From Account By Id
 */
export const deleteUserFromAccountById = (
    accountId: number,
    userId: number,
 ) => {
      
      
      return videobotFetch<boolean>(
      {url: `/account/${accountId}/user/${userId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteUserFromAccountByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserFromAccountById>>, TError,{accountId: number;userId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteUserFromAccountById>>, TError,{accountId: number;userId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserFromAccountById>>, {accountId: number;userId: number}> = (props) => {
          const {accountId,userId} = props ?? {};

          return  deleteUserFromAccountById(accountId,userId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteUserFromAccountByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserFromAccountById>>>
    
    export type DeleteUserFromAccountByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Delete User From Account By Id
 */
export const useDeleteUserFromAccountById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserFromAccountById>>, TError,{accountId: number;userId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteUserFromAccountByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update an user role from an account.

Allows: Owner, Superadmin
Forbidden on suspended.
 * @summary Update User Role From Account By Id
 */
export const updateUserRoleFromAccountById = (
    accountId: number,
    userId: number,
    roleAccountUpdate: BodyType<RoleAccountUpdate>,
 ) => {
      
      
      return videobotFetch<UserInAccountRead>(
      {url: `/account/${accountId}/user/${userId}/role`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: roleAccountUpdate
    },
      );
    }
  


export const getUpdateUserRoleFromAccountByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserRoleFromAccountById>>, TError,{accountId: number;userId: number;data: BodyType<RoleAccountUpdate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateUserRoleFromAccountById>>, TError,{accountId: number;userId: number;data: BodyType<RoleAccountUpdate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserRoleFromAccountById>>, {accountId: number;userId: number;data: BodyType<RoleAccountUpdate>}> = (props) => {
          const {accountId,userId,data} = props ?? {};

          return  updateUserRoleFromAccountById(accountId,userId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateUserRoleFromAccountByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserRoleFromAccountById>>>
    export type UpdateUserRoleFromAccountByIdMutationBody = BodyType<RoleAccountUpdate>
    export type UpdateUserRoleFromAccountByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Update User Role From Account By Id
 */
export const useUpdateUserRoleFromAccountById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserRoleFromAccountById>>, TError,{accountId: number;userId: number;data: BodyType<RoleAccountUpdate>}, TContext>, }
) => {

      const mutationOptions = getUpdateUserRoleFromAccountByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get all media records for an account by ID.

Permission: All roles
 * @summary Get All Media
 */
export const getAllMedia = (
    params: GetAllMediaParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<PaginationMediaRead>(
      {url: `/media`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetAllMediaQueryKey = (params: GetAllMediaParams,) => {
    return ['media',...(params ? [params]: [])] as const;
    }

    
export const getGetAllMediaQueryOptions = <TData = Awaited<ReturnType<typeof getAllMedia>>, TError = ErrorType<HTTPValidationError>>(params: GetAllMediaParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllMedia>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllMediaQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllMedia>>> = ({ signal }) => getAllMedia(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllMedia>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllMediaQueryResult = NonNullable<Awaited<ReturnType<typeof getAllMedia>>>
export type GetAllMediaQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get All Media
 */
export const useGetAllMedia = <TData = Awaited<ReturnType<typeof getAllMedia>>, TError = ErrorType<HTTPValidationError>>(
 params: GetAllMediaParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllMedia>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllMediaQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new media after uploaded.

Use URL in `uploadUrl` field `POST https://{Returned_Upload_URL}` with FormData, image in field `file` to upload.

Returned data upon upload will be like JSON below, use `PUT /account/{account_id}` to directly change either `chat_video_id` or `logo_id`, this will also automatically set media to `Ready`.

For video upload, the media status will be updated automatically when the video is ready on cloud.

Permission: All members in account, super admin.
```
{
    "result": {
        "id": "aaa-bbb-ccc-ddd-fff",
        "filename": "image.png",
        "uploaded": "2022-01-01T00:00:00.000Z",
        "requireSignedURLs": false,
        "variants": [
            "https://imagedelivery.net/abcXYZ/aaa-bbb-ccc-ddd-fff/public"
        ]
    },
    "success": true,
    "errors": [],
    "messages": []
}
```
 * @summary Create Media
 */
export const createMedia = (
    mediaCreate: BodyType<MediaCreate>,
 ) => {
      
      
      return videobotFetch<MediaCreateRead>(
      {url: `/media`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: mediaCreate
    },
      );
    }
  


export const getCreateMediaMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMedia>>, TError,{data: BodyType<MediaCreate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createMedia>>, TError,{data: BodyType<MediaCreate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMedia>>, {data: BodyType<MediaCreate>}> = (props) => {
          const {data} = props ?? {};

          return  createMedia(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateMediaMutationResult = NonNullable<Awaited<ReturnType<typeof createMedia>>>
    export type CreateMediaMutationBody = BodyType<MediaCreate>
    export type CreateMediaMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Media
 */
export const useCreateMedia = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMedia>>, TError,{data: BodyType<MediaCreate>}, TContext>, }
) => {

      const mutationOptions = getCreateMediaMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Use a tus client to upload video.
 * @summary Create Media Tus
 */
export const createMediaTus = (
    
 ) => {
      
      
      return videobotFetch<CreateMediaTus200>(
      {url: `/media/tus`, method: 'POST'
    },
      );
    }
  


export const getCreateMediaTusMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMediaTus>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createMediaTus>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMediaTus>>, void> = () => {
          

          return  createMediaTus()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateMediaTusMutationResult = NonNullable<Awaited<ReturnType<typeof createMediaTus>>>
    
    export type CreateMediaTusMutationError = ErrorType<unknown>

    /**
 * @summary Create Media Tus
 */
export const useCreateMediaTus = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMediaTus>>, TError,void, TContext>, }
) => {

      const mutationOptions = getCreateMediaTusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a media by id.
 * @summary Get Media By Id
 */
export const getMediaById = (
    mediaId: number,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<MediaReadOutput>(
      {url: `/media/${mediaId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetMediaByIdQueryKey = (mediaId: number,) => {
    return ['media',mediaId] as const;
    }

    
export const getGetMediaByIdQueryOptions = <TData = Awaited<ReturnType<typeof getMediaById>>, TError = ErrorType<void | HTTPValidationError>>(mediaId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMediaById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMediaByIdQueryKey(mediaId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMediaById>>> = ({ signal }) => getMediaById(mediaId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(mediaId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMediaById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMediaByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaById>>>
export type GetMediaByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Get Media By Id
 */
export const useGetMediaById = <TData = Awaited<ReturnType<typeof getMediaById>>, TError = ErrorType<void | HTTPValidationError>>(
 mediaId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMediaById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMediaByIdQueryOptions(mediaId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Delete a media by id.
 * @summary Delete Media By Id
 */
export const deleteMediaById = (
    mediaId: number,
 ) => {
      
      
      return videobotFetch<MediaReadOutput>(
      {url: `/media/${mediaId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteMediaByIdMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMediaById>>, TError,{mediaId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteMediaById>>, TError,{mediaId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMediaById>>, {mediaId: number}> = (props) => {
          const {mediaId} = props ?? {};

          return  deleteMediaById(mediaId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteMediaByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMediaById>>>
    
    export type DeleteMediaByIdMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Delete Media By Id
 */
export const useDeleteMediaById = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMediaById>>, TError,{mediaId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteMediaByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a media by id.
 * @summary Update Media By Id
 */
export const updateMediaById = (
    mediaId: number,
    mediaUpdate: BodyType<MediaUpdate>,
    params: UpdateMediaByIdParams,
 ) => {
      
      
      return videobotFetch<MediaReadOutput>(
      {url: `/media/${mediaId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: mediaUpdate,
        params
    },
      );
    }
  


export const getUpdateMediaByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMediaById>>, TError,{mediaId: number;data: BodyType<MediaUpdate>;params: UpdateMediaByIdParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateMediaById>>, TError,{mediaId: number;data: BodyType<MediaUpdate>;params: UpdateMediaByIdParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMediaById>>, {mediaId: number;data: BodyType<MediaUpdate>;params: UpdateMediaByIdParams}> = (props) => {
          const {mediaId,data,params} = props ?? {};

          return  updateMediaById(mediaId,data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateMediaByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateMediaById>>>
    export type UpdateMediaByIdMutationBody = BodyType<MediaUpdate>
    export type UpdateMediaByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Update Media By Id
 */
export const useUpdateMediaById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMediaById>>, TError,{mediaId: number;data: BodyType<MediaUpdate>;params: UpdateMediaByIdParams}, TContext>, }
) => {

      const mutationOptions = getUpdateMediaByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Upload caption.
 * @summary Upload Media Caption By Id
 */
export const uploadMediaCaptionById = (
    mediaId: number,
    bodyUploadMediaCaptionByIdMediaMediaIdCaptionPut: BodyType<BodyUploadMediaCaptionByIdMediaMediaIdCaptionPut>,
    params: UploadMediaCaptionByIdParams,
 ) => {
      
      const formData = new FormData();
formData.append('file', bodyUploadMediaCaptionByIdMediaMediaIdCaptionPut.file)

      return videobotFetch<CaptionBase>(
      {url: `/media/${mediaId}/caption`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData,
        params
    },
      );
    }
  


export const getUploadMediaCaptionByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadMediaCaptionById>>, TError,{mediaId: number;data: BodyType<BodyUploadMediaCaptionByIdMediaMediaIdCaptionPut>;params: UploadMediaCaptionByIdParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof uploadMediaCaptionById>>, TError,{mediaId: number;data: BodyType<BodyUploadMediaCaptionByIdMediaMediaIdCaptionPut>;params: UploadMediaCaptionByIdParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadMediaCaptionById>>, {mediaId: number;data: BodyType<BodyUploadMediaCaptionByIdMediaMediaIdCaptionPut>;params: UploadMediaCaptionByIdParams}> = (props) => {
          const {mediaId,data,params} = props ?? {};

          return  uploadMediaCaptionById(mediaId,data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadMediaCaptionByIdMutationResult = NonNullable<Awaited<ReturnType<typeof uploadMediaCaptionById>>>
    export type UploadMediaCaptionByIdMutationBody = BodyType<BodyUploadMediaCaptionByIdMediaMediaIdCaptionPut>
    export type UploadMediaCaptionByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Upload Media Caption By Id
 */
export const useUploadMediaCaptionById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadMediaCaptionById>>, TError,{mediaId: number;data: BodyType<BodyUploadMediaCaptionByIdMediaMediaIdCaptionPut>;params: UploadMediaCaptionByIdParams}, TContext>, }
) => {

      const mutationOptions = getUploadMediaCaptionByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete caption
 * @summary Delete Media Caption By Id
 */
export const deleteMediaCaptionById = (
    mediaId: number,
    captionId: number,
    params: DeleteMediaCaptionByIdParams,
 ) => {
      
      
      return videobotFetch<DeleteMediaCaptionById200>(
      {url: `/media/${mediaId}/caption/${captionId}`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteMediaCaptionByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMediaCaptionById>>, TError,{mediaId: number;captionId: number;params: DeleteMediaCaptionByIdParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteMediaCaptionById>>, TError,{mediaId: number;captionId: number;params: DeleteMediaCaptionByIdParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMediaCaptionById>>, {mediaId: number;captionId: number;params: DeleteMediaCaptionByIdParams}> = (props) => {
          const {mediaId,captionId,params} = props ?? {};

          return  deleteMediaCaptionById(mediaId,captionId,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteMediaCaptionByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMediaCaptionById>>>
    
    export type DeleteMediaCaptionByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Delete Media Caption By Id
 */
export const useDeleteMediaCaptionById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMediaCaptionById>>, TError,{mediaId: number;captionId: number;params: DeleteMediaCaptionByIdParams}, TContext>, }
) => {

      const mutationOptions = getDeleteMediaCaptionByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Media Webhook
 */
export const mediaWebhook = (
    
 ) => {
      
      
      return videobotFetch<MediaWebhook200>(
      {url: `/media/webhook`, method: 'POST'
    },
      );
    }
  


export const getMediaWebhookMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof mediaWebhook>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof mediaWebhook>>, TError,void, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof mediaWebhook>>, void> = () => {
          

          return  mediaWebhook()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type MediaWebhookMutationResult = NonNullable<Awaited<ReturnType<typeof mediaWebhook>>>
    
    export type MediaWebhookMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Media Webhook
 */
export const useMediaWebhook = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof mediaWebhook>>, TError,void, TContext>, }
) => {

      const mutationOptions = getMediaWebhookMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read all bots in an account, `account_id` query is required.

Permission: All roles
 * @summary Read All Bots
 */
export const readAllBots = (
    params: ReadAllBotsParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<PaginationBotListSingleRead>(
      {url: `/bot`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadAllBotsQueryKey = (params: ReadAllBotsParams,) => {
    return ['bot',...(params ? [params]: [])] as const;
    }

    
export const getReadAllBotsQueryOptions = <TData = Awaited<ReturnType<typeof readAllBots>>, TError = ErrorType<HTTPValidationError>>(params: ReadAllBotsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAllBots>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAllBotsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAllBots>>> = ({ signal }) => readAllBots(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAllBots>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAllBotsQueryResult = NonNullable<Awaited<ReturnType<typeof readAllBots>>>
export type ReadAllBotsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read All Bots
 */
export const useReadAllBots = <TData = Awaited<ReturnType<typeof readAllBots>>, TError = ErrorType<HTTPValidationError>>(
 params: ReadAllBotsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAllBots>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAllBotsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new videobot.

Permission: All roles
 * @summary Create Bot
 */
export const createBot = (
    botCreate: BodyType<BotCreate>,
 ) => {
      
      
      return videobotFetch<BotRead>(
      {url: `/bot`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: botCreate
    },
      );
    }
  


export const getCreateBotMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBot>>, TError,{data: BodyType<BotCreate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createBot>>, TError,{data: BodyType<BotCreate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createBot>>, {data: BodyType<BotCreate>}> = (props) => {
          const {data} = props ?? {};

          return  createBot(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateBotMutationResult = NonNullable<Awaited<ReturnType<typeof createBot>>>
    export type CreateBotMutationBody = BodyType<BotCreate>
    export type CreateBotMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Bot
 */
export const useCreateBot = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBot>>, TError,{data: BodyType<BotCreate>}, TContext>, }
) => {

      const mutationOptions = getCreateBotMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read all bot embeds in an account, `account_id` query is required.

Permission: All roles
 * @summary Read All Bot Embeds
 */
export const readAllBotEmbeds = (
    params: ReadAllBotEmbedsParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<PaginationBotEmbedRead>(
      {url: `/bot/embed`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadAllBotEmbedsQueryKey = (params: ReadAllBotEmbedsParams,) => {
    return ['bot','embed',...(params ? [params]: [])] as const;
    }

    
export const getReadAllBotEmbedsQueryOptions = <TData = Awaited<ReturnType<typeof readAllBotEmbeds>>, TError = ErrorType<HTTPValidationError>>(params: ReadAllBotEmbedsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAllBotEmbeds>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAllBotEmbedsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAllBotEmbeds>>> = ({ signal }) => readAllBotEmbeds(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAllBotEmbeds>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAllBotEmbedsQueryResult = NonNullable<Awaited<ReturnType<typeof readAllBotEmbeds>>>
export type ReadAllBotEmbedsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read All Bot Embeds
 */
export const useReadAllBotEmbeds = <TData = Awaited<ReturnType<typeof readAllBotEmbeds>>, TError = ErrorType<HTTPValidationError>>(
 params: ReadAllBotEmbedsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAllBotEmbeds>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAllBotEmbedsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Read bot by id.

Permission: All roles
 * @summary Read Bot By Id
 */
export const readBotById = (
    botId: number,
    params?: ReadBotByIdParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<BotRead>(
      {url: `/bot/${botId}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadBotByIdQueryKey = (botId: number,
    params?: ReadBotByIdParams,) => {
    return ['bot',botId,...(params ? [params]: [])] as const;
    }

    
export const getReadBotByIdQueryOptions = <TData = Awaited<ReturnType<typeof readBotById>>, TError = ErrorType<void | HTTPValidationError>>(botId: number,
    params?: ReadBotByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readBotById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadBotByIdQueryKey(botId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readBotById>>> = ({ signal }) => readBotById(botId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(botId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readBotById>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadBotByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readBotById>>>
export type ReadBotByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Bot By Id
 */
export const useReadBotById = <TData = Awaited<ReturnType<typeof readBotById>>, TError = ErrorType<void | HTTPValidationError>>(
 botId: number,
    params?: ReadBotByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readBotById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadBotByIdQueryOptions(botId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Delete a videobot by id.

Permission: Admin, Owner or Bot's creator
 * @summary Delete Bot By Id
 */
export const deleteBotById = (
    botId: number,
 ) => {
      
      
      return videobotFetch<BotRead>(
      {url: `/bot/${botId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteBotByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteBotById>>, TError,{botId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteBotById>>, TError,{botId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteBotById>>, {botId: number}> = (props) => {
          const {botId} = props ?? {};

          return  deleteBotById(botId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteBotByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteBotById>>>
    
    export type DeleteBotByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Delete Bot By Id
 */
export const useDeleteBotById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteBotById>>, TError,{botId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteBotByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Partially update a videobot by id.

Permission: Admin, Owner or Bot's creator
 * @summary Update Bot By Id
 */
export const updateBotById = (
    botId: number,
    botUpdate: BodyType<BotUpdate>,
 ) => {
      
      
      return videobotFetch<BotRead>(
      {url: `/bot/${botId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: botUpdate
    },
      );
    }
  


export const getUpdateBotByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBotById>>, TError,{botId: number;data: BodyType<BotUpdate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateBotById>>, TError,{botId: number;data: BodyType<BotUpdate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBotById>>, {botId: number;data: BodyType<BotUpdate>}> = (props) => {
          const {botId,data} = props ?? {};

          return  updateBotById(botId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateBotByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateBotById>>>
    export type UpdateBotByIdMutationBody = BodyType<BotUpdate>
    export type UpdateBotByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Update Bot By Id
 */
export const useUpdateBotById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBotById>>, TError,{botId: number;data: BodyType<BotUpdate>}, TContext>, }
) => {

      const mutationOptions = getUpdateBotByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read bot widget by id.

Permission: All roles
 * @summary Read Bot Widget By Id
 */
export const readBotWidgetById = (
    botId: number,
    params?: ReadBotWidgetByIdParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<BotWidgetDashboardRead>(
      {url: `/bot/${botId}/widget`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadBotWidgetByIdQueryKey = (botId: number,
    params?: ReadBotWidgetByIdParams,) => {
    return ['bot',botId,'widget',...(params ? [params]: [])] as const;
    }

    
export const getReadBotWidgetByIdQueryOptions = <TData = Awaited<ReturnType<typeof readBotWidgetById>>, TError = ErrorType<void | HTTPValidationError>>(botId: number,
    params?: ReadBotWidgetByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readBotWidgetById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadBotWidgetByIdQueryKey(botId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readBotWidgetById>>> = ({ signal }) => readBotWidgetById(botId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(botId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readBotWidgetById>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadBotWidgetByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readBotWidgetById>>>
export type ReadBotWidgetByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Bot Widget By Id
 */
export const useReadBotWidgetById = <TData = Awaited<ReturnType<typeof readBotWidgetById>>, TError = ErrorType<void | HTTPValidationError>>(
 botId: number,
    params?: ReadBotWidgetByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readBotWidgetById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadBotWidgetByIdQueryOptions(botId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Bot Widget By Id
 */
export const updateBotWidgetById = (
    botId: number,
    botWidgetUpdate: BodyType<BotWidgetUpdate>,
 ) => {
      
      
      return videobotFetch<BotWidgetUpdateResponse>(
      {url: `/bot/${botId}/widget`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: botWidgetUpdate
    },
      );
    }
  


export const getUpdateBotWidgetByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBotWidgetById>>, TError,{botId: number;data: BodyType<BotWidgetUpdate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateBotWidgetById>>, TError,{botId: number;data: BodyType<BotWidgetUpdate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBotWidgetById>>, {botId: number;data: BodyType<BotWidgetUpdate>}> = (props) => {
          const {botId,data} = props ?? {};

          return  updateBotWidgetById(botId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateBotWidgetByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateBotWidgetById>>>
    export type UpdateBotWidgetByIdMutationBody = BodyType<BotWidgetUpdate>
    export type UpdateBotWidgetByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Update Bot Widget By Id
 */
export const useUpdateBotWidgetById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBotWidgetById>>, TError,{botId: number;data: BodyType<BotWidgetUpdate>}, TContext>, }
) => {

      const mutationOptions = getUpdateBotWidgetByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read bot embed by public id.

Permission: All roles
 * @summary Read Bot Embed By Public Id
 */
export const readBotEmbedByPublicId = (
    botPublicId: string,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<ReadBotEmbedByPublicId200>(
      {url: `/bot/${botPublicId}/embed`, method: 'GET', signal
    },
      );
    }
  

export const getReadBotEmbedByPublicIdQueryKey = (botPublicId: string,) => {
    return ['bot',botPublicId,'embed'] as const;
    }

    
export const getReadBotEmbedByPublicIdQueryOptions = <TData = Awaited<ReturnType<typeof readBotEmbedByPublicId>>, TError = ErrorType<void | HTTPValidationError>>(botPublicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readBotEmbedByPublicId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadBotEmbedByPublicIdQueryKey(botPublicId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readBotEmbedByPublicId>>> = ({ signal }) => readBotEmbedByPublicId(botPublicId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(botPublicId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readBotEmbedByPublicId>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadBotEmbedByPublicIdQueryResult = NonNullable<Awaited<ReturnType<typeof readBotEmbedByPublicId>>>
export type ReadBotEmbedByPublicIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Bot Embed By Public Id
 */
export const useReadBotEmbedByPublicId = <TData = Awaited<ReturnType<typeof readBotEmbedByPublicId>>, TError = ErrorType<void | HTTPValidationError>>(
 botPublicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readBotEmbedByPublicId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadBotEmbedByPublicIdQueryOptions(botPublicId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Read bot widget by public id.

Permission: All roles
 * @summary Read Bot Widget Embed By Public Id
 */
export const readBotWidgetEmbedByPublicId = (
    botPublicId: string,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<ReadBotWidgetEmbedByPublicId200>(
      {url: `/bot/${botPublicId}/widget-embed`, method: 'GET', signal
    },
      );
    }
  

export const getReadBotWidgetEmbedByPublicIdQueryKey = (botPublicId: string,) => {
    return ['bot',botPublicId,'widget-embed'] as const;
    }

    
export const getReadBotWidgetEmbedByPublicIdQueryOptions = <TData = Awaited<ReturnType<typeof readBotWidgetEmbedByPublicId>>, TError = ErrorType<void | HTTPValidationError>>(botPublicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readBotWidgetEmbedByPublicId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadBotWidgetEmbedByPublicIdQueryKey(botPublicId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readBotWidgetEmbedByPublicId>>> = ({ signal }) => readBotWidgetEmbedByPublicId(botPublicId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(botPublicId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readBotWidgetEmbedByPublicId>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadBotWidgetEmbedByPublicIdQueryResult = NonNullable<Awaited<ReturnType<typeof readBotWidgetEmbedByPublicId>>>
export type ReadBotWidgetEmbedByPublicIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Bot Widget Embed By Public Id
 */
export const useReadBotWidgetEmbedByPublicId = <TData = Awaited<ReturnType<typeof readBotWidgetEmbedByPublicId>>, TError = ErrorType<void | HTTPValidationError>>(
 botPublicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readBotWidgetEmbedByPublicId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadBotWidgetEmbedByPublicIdQueryOptions(botPublicId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Bot Submit Form
 */
export const botSubmitForm = (
    botPublicId: string,
    botSubmitForm: BodyType<BotSubmitForm>,
 ) => {
      
      
      return videobotFetch<BotSubmitForm200>(
      {url: `/bot/${botPublicId}/lead`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: botSubmitForm
    },
      );
    }
  


export const getBotSubmitFormMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof botSubmitForm>>, TError,{botPublicId: string;data: BodyType<BotSubmitForm>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof botSubmitForm>>, TError,{botPublicId: string;data: BodyType<BotSubmitForm>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof botSubmitForm>>, {botPublicId: string;data: BodyType<BotSubmitForm>}> = (props) => {
          const {botPublicId,data} = props ?? {};

          return  botSubmitForm(botPublicId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type BotSubmitFormMutationResult = NonNullable<Awaited<ReturnType<typeof botSubmitForm>>>
    export type BotSubmitFormMutationBody = BodyType<BotSubmitForm>
    export type BotSubmitFormMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Bot Submit Form
 */
export const useBotSubmitForm = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof botSubmitForm>>, TError,{botPublicId: string;data: BodyType<BotSubmitForm>}, TContext>, }
) => {

      const mutationOptions = getBotSubmitFormMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Bot Submit Form Legacy
 */
export const botSubmitFormLegacy = (
    botSubmitFormLegacy: BodyType<BotSubmitFormLegacy>,
 ) => {
      
      
      return videobotFetch<BotSubmitFormLegacy200>(
      {url: `/bot/submit-form`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: botSubmitFormLegacy
    },
      );
    }
  


export const getBotSubmitFormLegacyMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof botSubmitFormLegacy>>, TError,{data: BodyType<BotSubmitFormLegacy>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof botSubmitFormLegacy>>, TError,{data: BodyType<BotSubmitFormLegacy>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof botSubmitFormLegacy>>, {data: BodyType<BotSubmitFormLegacy>}> = (props) => {
          const {data} = props ?? {};

          return  botSubmitFormLegacy(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type BotSubmitFormLegacyMutationResult = NonNullable<Awaited<ReturnType<typeof botSubmitFormLegacy>>>
    export type BotSubmitFormLegacyMutationBody = BodyType<BotSubmitFormLegacy>
    export type BotSubmitFormLegacyMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Bot Submit Form Legacy
 */
export const useBotSubmitFormLegacy = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof botSubmitFormLegacy>>, TError,{data: BodyType<BotSubmitFormLegacy>}, TContext>, }
) => {

      const mutationOptions = getBotSubmitFormLegacyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Clone Bot By Id
 */
export const cloneBotById = (
    botId: number,
 ) => {
      
      
      return videobotFetch<BotRead>(
      {url: `/bot/${botId}/clone`, method: 'POST'
    },
      );
    }
  


export const getCloneBotByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloneBotById>>, TError,{botId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof cloneBotById>>, TError,{botId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloneBotById>>, {botId: number}> = (props) => {
          const {botId} = props ?? {};

          return  cloneBotById(botId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CloneBotByIdMutationResult = NonNullable<Awaited<ReturnType<typeof cloneBotById>>>
    
    export type CloneBotByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Clone Bot By Id
 */
export const useCloneBotById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloneBotById>>, TError,{botId: number}, TContext>, }
) => {

      const mutationOptions = getCloneBotByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read all bot revisions by id.

Permission: All roles
 * @summary Read All Bot Revisions By Bot Id
 */
export const readAllBotRevisionsByBotId = (
    botId: number,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<BotRevisionRead[]>(
      {url: `/bot/${botId}/revision`, method: 'GET', signal
    },
      );
    }
  

export const getReadAllBotRevisionsByBotIdQueryKey = (botId: number,) => {
    return ['bot',botId,'revision'] as const;
    }

    
export const getReadAllBotRevisionsByBotIdQueryOptions = <TData = Awaited<ReturnType<typeof readAllBotRevisionsByBotId>>, TError = ErrorType<void | HTTPValidationError>>(botId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAllBotRevisionsByBotId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAllBotRevisionsByBotIdQueryKey(botId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAllBotRevisionsByBotId>>> = ({ signal }) => readAllBotRevisionsByBotId(botId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(botId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAllBotRevisionsByBotId>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAllBotRevisionsByBotIdQueryResult = NonNullable<Awaited<ReturnType<typeof readAllBotRevisionsByBotId>>>
export type ReadAllBotRevisionsByBotIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read All Bot Revisions By Bot Id
 */
export const useReadAllBotRevisionsByBotId = <TData = Awaited<ReturnType<typeof readAllBotRevisionsByBotId>>, TError = ErrorType<void | HTTPValidationError>>(
 botId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAllBotRevisionsByBotId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAllBotRevisionsByBotIdQueryOptions(botId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Read bot revision by id.

Permission: All roles
 * @summary Read Bot Revision By Id
 */
export const readBotRevisionById = (
    botId: number,
    revisionId: number,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<BotRevisionRead>(
      {url: `/bot/${botId}/revision/${revisionId}`, method: 'GET', signal
    },
      );
    }
  

export const getReadBotRevisionByIdQueryKey = (botId: number,
    revisionId: number,) => {
    return ['bot',botId,'revision',revisionId] as const;
    }

    
export const getReadBotRevisionByIdQueryOptions = <TData = Awaited<ReturnType<typeof readBotRevisionById>>, TError = ErrorType<void | HTTPValidationError>>(botId: number,
    revisionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readBotRevisionById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadBotRevisionByIdQueryKey(botId,revisionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readBotRevisionById>>> = ({ signal }) => readBotRevisionById(botId,revisionId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(botId && revisionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readBotRevisionById>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadBotRevisionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readBotRevisionById>>>
export type ReadBotRevisionByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Bot Revision By Id
 */
export const useReadBotRevisionById = <TData = Awaited<ReturnType<typeof readBotRevisionById>>, TError = ErrorType<void | HTTPValidationError>>(
 botId: number,
    revisionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readBotRevisionById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadBotRevisionByIdQueryOptions(botId,revisionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update bot revision status by id.

Permission: All roles
 * @summary Update Bot Revision Status
 */
export const updateBotRevisionStatus = (
    botId: number,
    revisionId: number,
    botRevisionStatusUpdate: BodyType<BotRevisionStatusUpdate>,
 ) => {
      
      
      return videobotFetch<BotRevisionRead>(
      {url: `/bot/${botId}/revision/${revisionId}/status`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: botRevisionStatusUpdate
    },
      );
    }
  


export const getUpdateBotRevisionStatusMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBotRevisionStatus>>, TError,{botId: number;revisionId: number;data: BodyType<BotRevisionStatusUpdate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateBotRevisionStatus>>, TError,{botId: number;revisionId: number;data: BodyType<BotRevisionStatusUpdate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBotRevisionStatus>>, {botId: number;revisionId: number;data: BodyType<BotRevisionStatusUpdate>}> = (props) => {
          const {botId,revisionId,data} = props ?? {};

          return  updateBotRevisionStatus(botId,revisionId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateBotRevisionStatusMutationResult = NonNullable<Awaited<ReturnType<typeof updateBotRevisionStatus>>>
    export type UpdateBotRevisionStatusMutationBody = BodyType<BotRevisionStatusUpdate>
    export type UpdateBotRevisionStatusMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Update Bot Revision Status
 */
export const useUpdateBotRevisionStatus = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBotRevisionStatus>>, TError,{botId: number;revisionId: number;data: BodyType<BotRevisionStatusUpdate>}, TContext>, }
) => {

      const mutationOptions = getUpdateBotRevisionStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read all tags within an account with optional filtering based on query input.

Permission: All roles (or adjust as per your needs)
 * @summary Read All Tags
 */
export const readAllTags = (
    params: ReadAllTagsParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<PaginationTagSchema>(
      {url: `/tag`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadAllTagsQueryKey = (params: ReadAllTagsParams,) => {
    return ['tag',...(params ? [params]: [])] as const;
    }

    
export const getReadAllTagsQueryOptions = <TData = Awaited<ReturnType<typeof readAllTags>>, TError = ErrorType<HTTPValidationError>>(params: ReadAllTagsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAllTags>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAllTagsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAllTags>>> = ({ signal }) => readAllTags(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAllTags>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAllTagsQueryResult = NonNullable<Awaited<ReturnType<typeof readAllTags>>>
export type ReadAllTagsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read All Tags
 */
export const useReadAllTags = <TData = Awaited<ReturnType<typeof readAllTags>>, TError = ErrorType<HTTPValidationError>>(
 params: ReadAllTagsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAllTags>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAllTagsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new tag for an account.

Permission: All roles
 * @summary Create Tag
 */
export const createTag = (
    tagCreate: BodyType<TagCreate>,
 ) => {
      
      
      return videobotFetch<TagSchema>(
      {url: `/tag`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: tagCreate
    },
      );
    }
  


export const getCreateTagMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTag>>, TError,{data: BodyType<TagCreate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createTag>>, TError,{data: BodyType<TagCreate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTag>>, {data: BodyType<TagCreate>}> = (props) => {
          const {data} = props ?? {};

          return  createTag(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateTagMutationResult = NonNullable<Awaited<ReturnType<typeof createTag>>>
    export type CreateTagMutationBody = BodyType<TagCreate>
    export type CreateTagMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Tag
 */
export const useCreateTag = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTag>>, TError,{data: BodyType<TagCreate>}, TContext>, }
) => {

      const mutationOptions = getCreateTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read all widgets in an account, `account_id` query is required.

Permission: All roles
 * @summary Read All Widgets
 */
export const readAllWidgets = (
    params: ReadAllWidgetsParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<PaginationWidgetRead>(
      {url: `/widget`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadAllWidgetsQueryKey = (params: ReadAllWidgetsParams,) => {
    return ['widget',...(params ? [params]: [])] as const;
    }

    
export const getReadAllWidgetsQueryOptions = <TData = Awaited<ReturnType<typeof readAllWidgets>>, TError = ErrorType<HTTPValidationError>>(params: ReadAllWidgetsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAllWidgets>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAllWidgetsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAllWidgets>>> = ({ signal }) => readAllWidgets(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAllWidgets>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAllWidgetsQueryResult = NonNullable<Awaited<ReturnType<typeof readAllWidgets>>>
export type ReadAllWidgetsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read All Widgets
 */
export const useReadAllWidgets = <TData = Awaited<ReturnType<typeof readAllWidgets>>, TError = ErrorType<HTTPValidationError>>(
 params: ReadAllWidgetsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAllWidgets>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAllWidgetsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new widget in an account.

Permission: All roles
 * @summary Create Widget
 */
export const createWidget = (
    widgetCreate: BodyType<WidgetCreate>,
 ) => {
      
      
      return videobotFetch<WidgetRead>(
      {url: `/widget`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: widgetCreate
    },
      );
    }
  


export const getCreateWidgetMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWidget>>, TError,{data: BodyType<WidgetCreate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createWidget>>, TError,{data: BodyType<WidgetCreate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createWidget>>, {data: BodyType<WidgetCreate>}> = (props) => {
          const {data} = props ?? {};

          return  createWidget(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateWidgetMutationResult = NonNullable<Awaited<ReturnType<typeof createWidget>>>
    export type CreateWidgetMutationBody = BodyType<WidgetCreate>
    export type CreateWidgetMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Widget
 */
export const useCreateWidget = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWidget>>, TError,{data: BodyType<WidgetCreate>}, TContext>, }
) => {

      const mutationOptions = getCreateWidgetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read widget by id.

Permission: All roles
 * @summary Read Widget By Id
 */
export const readWidgetById = (
    widgetId: number,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<WidgetRead>(
      {url: `/widget/${widgetId}`, method: 'GET', signal
    },
      );
    }
  

export const getReadWidgetByIdQueryKey = (widgetId: number,) => {
    return ['widget',widgetId] as const;
    }

    
export const getReadWidgetByIdQueryOptions = <TData = Awaited<ReturnType<typeof readWidgetById>>, TError = ErrorType<void | HTTPValidationError>>(widgetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readWidgetById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadWidgetByIdQueryKey(widgetId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readWidgetById>>> = ({ signal }) => readWidgetById(widgetId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(widgetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readWidgetById>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadWidgetByIdQueryResult = NonNullable<Awaited<ReturnType<typeof readWidgetById>>>
export type ReadWidgetByIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Widget By Id
 */
export const useReadWidgetById = <TData = Awaited<ReturnType<typeof readWidgetById>>, TError = ErrorType<void | HTTPValidationError>>(
 widgetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readWidgetById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadWidgetByIdQueryOptions(widgetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Delete a widget by id.

Permission: Admin, Owner or Bot's creator
 * @summary Delete Widget By Id
 */
export const deleteWidgetById = (
    widgetId: number,
 ) => {
      
      
      return videobotFetch<WidgetRead>(
      {url: `/widget/${widgetId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteWidgetByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWidgetById>>, TError,{widgetId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteWidgetById>>, TError,{widgetId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWidgetById>>, {widgetId: number}> = (props) => {
          const {widgetId} = props ?? {};

          return  deleteWidgetById(widgetId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteWidgetByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWidgetById>>>
    
    export type DeleteWidgetByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Delete Widget By Id
 */
export const useDeleteWidgetById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWidgetById>>, TError,{widgetId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteWidgetByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Partially update a widget by id.

Permission: Admin, Owner or Bot's creator
 * @summary Update Widget By Id
 */
export const updateWidgetById = (
    widgetId: number,
    widgetUpdate: BodyType<WidgetUpdate>,
 ) => {
      
      
      return videobotFetch<WidgetRead>(
      {url: `/widget/${widgetId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: widgetUpdate
    },
      );
    }
  


export const getUpdateWidgetByIdMutationOptions = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWidgetById>>, TError,{widgetId: number;data: BodyType<WidgetUpdate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateWidgetById>>, TError,{widgetId: number;data: BodyType<WidgetUpdate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWidgetById>>, {widgetId: number;data: BodyType<WidgetUpdate>}> = (props) => {
          const {widgetId,data} = props ?? {};

          return  updateWidgetById(widgetId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateWidgetByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateWidgetById>>>
    export type UpdateWidgetByIdMutationBody = BodyType<WidgetUpdate>
    export type UpdateWidgetByIdMutationError = ErrorType<void | HTTPValidationError>

    /**
 * @summary Update Widget By Id
 */
export const useUpdateWidgetById = <TError = ErrorType<void | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWidgetById>>, TError,{widgetId: number;data: BodyType<WidgetUpdate>}, TContext>, }
) => {

      const mutationOptions = getUpdateWidgetByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Read widget embed by id.
 * @summary Read Widget Embed By Public Id
 */
export const readWidgetEmbedByPublicId = (
    widgetId: string,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<WidgetEmbedRead>(
      {url: `/widget/${widgetId}/embed`, method: 'GET', signal
    },
      );
    }
  

export const getReadWidgetEmbedByPublicIdQueryKey = (widgetId: string,) => {
    return ['widget',widgetId,'embed'] as const;
    }

    
export const getReadWidgetEmbedByPublicIdQueryOptions = <TData = Awaited<ReturnType<typeof readWidgetEmbedByPublicId>>, TError = ErrorType<void | HTTPValidationError>>(widgetId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readWidgetEmbedByPublicId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadWidgetEmbedByPublicIdQueryKey(widgetId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readWidgetEmbedByPublicId>>> = ({ signal }) => readWidgetEmbedByPublicId(widgetId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(widgetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readWidgetEmbedByPublicId>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadWidgetEmbedByPublicIdQueryResult = NonNullable<Awaited<ReturnType<typeof readWidgetEmbedByPublicId>>>
export type ReadWidgetEmbedByPublicIdQueryError = ErrorType<void | HTTPValidationError>

/**
 * @summary Read Widget Embed By Public Id
 */
export const useReadWidgetEmbedByPublicId = <TData = Awaited<ReturnType<typeof readWidgetEmbedByPublicId>>, TError = ErrorType<void | HTTPValidationError>>(
 widgetId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readWidgetEmbedByPublicId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadWidgetEmbedByPublicIdQueryOptions(widgetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Read Paginate Bot Analytics
 */
export const readPaginateBotAnalytics = (
    params: ReadPaginateBotAnalyticsParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<PaginationAnalyticsRead>(
      {url: `/analytics`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadPaginateBotAnalyticsQueryKey = (params: ReadPaginateBotAnalyticsParams,) => {
    return ['analytics',...(params ? [params]: [])] as const;
    }

    
export const getReadPaginateBotAnalyticsQueryOptions = <TData = Awaited<ReturnType<typeof readPaginateBotAnalytics>>, TError = ErrorType<HTTPValidationError>>(params: ReadPaginateBotAnalyticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readPaginateBotAnalytics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadPaginateBotAnalyticsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readPaginateBotAnalytics>>> = ({ signal }) => readPaginateBotAnalytics(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readPaginateBotAnalytics>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadPaginateBotAnalyticsQueryResult = NonNullable<Awaited<ReturnType<typeof readPaginateBotAnalytics>>>
export type ReadPaginateBotAnalyticsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read Paginate Bot Analytics
 */
export const useReadPaginateBotAnalytics = <TData = Awaited<ReturnType<typeof readPaginateBotAnalytics>>, TError = ErrorType<HTTPValidationError>>(
 params: ReadPaginateBotAnalyticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readPaginateBotAnalytics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadPaginateBotAnalyticsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Analytics Event
 */
export const createAnalyticsEvent = (
    analyticEventCreate: BodyType<AnalyticEventCreate>,
 ) => {
      
      
      return videobotFetch<AnalyticEventRead>(
      {url: `/analytics`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: analyticEventCreate
    },
      );
    }
  


export const getCreateAnalyticsEventMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAnalyticsEvent>>, TError,{data: BodyType<AnalyticEventCreate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createAnalyticsEvent>>, TError,{data: BodyType<AnalyticEventCreate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAnalyticsEvent>>, {data: BodyType<AnalyticEventCreate>}> = (props) => {
          const {data} = props ?? {};

          return  createAnalyticsEvent(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateAnalyticsEventMutationResult = NonNullable<Awaited<ReturnType<typeof createAnalyticsEvent>>>
    export type CreateAnalyticsEventMutationBody = BodyType<AnalyticEventCreate>
    export type CreateAnalyticsEventMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Analytics Event
 */
export const useCreateAnalyticsEvent = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAnalyticsEvent>>, TError,{data: BodyType<AnalyticEventCreate>}, TContext>, }
) => {

      const mutationOptions = getCreateAnalyticsEventMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Read Account Total Analytics
 */
export const readAccountTotalAnalytics = (
    accountId: number,
    params: ReadAccountTotalAnalyticsParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<AnalyticsAccountTotalRead>(
      {url: `/analytics/total/${accountId}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadAccountTotalAnalyticsQueryKey = (accountId: number,
    params: ReadAccountTotalAnalyticsParams,) => {
    return ['analytics','total',accountId,...(params ? [params]: [])] as const;
    }

    
export const getReadAccountTotalAnalyticsQueryOptions = <TData = Awaited<ReturnType<typeof readAccountTotalAnalytics>>, TError = ErrorType<HTTPValidationError>>(accountId: number,
    params: ReadAccountTotalAnalyticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountTotalAnalytics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAccountTotalAnalyticsQueryKey(accountId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAccountTotalAnalytics>>> = ({ signal }) => readAccountTotalAnalytics(accountId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAccountTotalAnalytics>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAccountTotalAnalyticsQueryResult = NonNullable<Awaited<ReturnType<typeof readAccountTotalAnalytics>>>
export type ReadAccountTotalAnalyticsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read Account Total Analytics
 */
export const useReadAccountTotalAnalytics = <TData = Awaited<ReturnType<typeof readAccountTotalAnalytics>>, TError = ErrorType<HTTPValidationError>>(
 accountId: number,
    params: ReadAccountTotalAnalyticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountTotalAnalytics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAccountTotalAnalyticsQueryOptions(accountId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Read Paginated Widget Analytics
 */
export const readPaginatedWidgetAnalytics = (
    accountId: number,
    params?: ReadPaginatedWidgetAnalyticsParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<PaginationAnalyticsWidgetRead>(
      {url: `/analytics/widget/${accountId}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadPaginatedWidgetAnalyticsQueryKey = (accountId: number,
    params?: ReadPaginatedWidgetAnalyticsParams,) => {
    return ['analytics','widget',accountId,...(params ? [params]: [])] as const;
    }

    
export const getReadPaginatedWidgetAnalyticsQueryOptions = <TData = Awaited<ReturnType<typeof readPaginatedWidgetAnalytics>>, TError = ErrorType<HTTPValidationError>>(accountId: number,
    params?: ReadPaginatedWidgetAnalyticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readPaginatedWidgetAnalytics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadPaginatedWidgetAnalyticsQueryKey(accountId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readPaginatedWidgetAnalytics>>> = ({ signal }) => readPaginatedWidgetAnalytics(accountId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readPaginatedWidgetAnalytics>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadPaginatedWidgetAnalyticsQueryResult = NonNullable<Awaited<ReturnType<typeof readPaginatedWidgetAnalytics>>>
export type ReadPaginatedWidgetAnalyticsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read Paginated Widget Analytics
 */
export const useReadPaginatedWidgetAnalytics = <TData = Awaited<ReturnType<typeof readPaginatedWidgetAnalytics>>, TError = ErrorType<HTTPValidationError>>(
 accountId: number,
    params?: ReadPaginatedWidgetAnalyticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readPaginatedWidgetAnalytics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadPaginatedWidgetAnalyticsQueryOptions(accountId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Read Detail Bot Analytic
 */
export const readDetailBotAnalytic = (
    botId: number,
    params: ReadDetailBotAnalyticParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<AnalyticDetailBotRead>(
      {url: `/analytics/bot/${botId}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadDetailBotAnalyticQueryKey = (botId: number,
    params: ReadDetailBotAnalyticParams,) => {
    return ['analytics','bot',botId,...(params ? [params]: [])] as const;
    }

    
export const getReadDetailBotAnalyticQueryOptions = <TData = Awaited<ReturnType<typeof readDetailBotAnalytic>>, TError = ErrorType<HTTPValidationError>>(botId: number,
    params: ReadDetailBotAnalyticParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readDetailBotAnalytic>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadDetailBotAnalyticQueryKey(botId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readDetailBotAnalytic>>> = ({ signal }) => readDetailBotAnalytic(botId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(botId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readDetailBotAnalytic>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadDetailBotAnalyticQueryResult = NonNullable<Awaited<ReturnType<typeof readDetailBotAnalytic>>>
export type ReadDetailBotAnalyticQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read Detail Bot Analytic
 */
export const useReadDetailBotAnalytic = <TData = Awaited<ReturnType<typeof readDetailBotAnalytic>>, TError = ErrorType<HTTPValidationError>>(
 botId: number,
    params: ReadDetailBotAnalyticParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readDetailBotAnalytic>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadDetailBotAnalyticQueryOptions(botId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Read Analytic Report
 */
export const readAnalyticReport = (
    params: ReadAnalyticReportParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<AnalyticReportBotRead[]>(
      {url: `/analytics/report`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadAnalyticReportQueryKey = (params: ReadAnalyticReportParams,) => {
    return ['analytics','report',...(params ? [params]: [])] as const;
    }

    
export const getReadAnalyticReportQueryOptions = <TData = Awaited<ReturnType<typeof readAnalyticReport>>, TError = ErrorType<HTTPValidationError>>(params: ReadAnalyticReportParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAnalyticReport>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAnalyticReportQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAnalyticReport>>> = ({ signal }) => readAnalyticReport(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAnalyticReport>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAnalyticReportQueryResult = NonNullable<Awaited<ReturnType<typeof readAnalyticReport>>>
export type ReadAnalyticReportQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read Analytic Report
 */
export const useReadAnalyticReport = <TData = Awaited<ReturnType<typeof readAnalyticReport>>, TError = ErrorType<HTTPValidationError>>(
 params: ReadAnalyticReportParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAnalyticReport>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAnalyticReportQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Read Account Daily Total Analytics
 */
export const readAccountDailyTotalAnalytics = (
    accountId: number,
    params: ReadAccountDailyTotalAnalyticsParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<AnalyticsDailyTotalRead[]>(
      {url: `/analytics/total/${accountId}/daily`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getReadAccountDailyTotalAnalyticsQueryKey = (accountId: number,
    params: ReadAccountDailyTotalAnalyticsParams,) => {
    return ['analytics','total',accountId,'daily',...(params ? [params]: [])] as const;
    }

    
export const getReadAccountDailyTotalAnalyticsQueryOptions = <TData = Awaited<ReturnType<typeof readAccountDailyTotalAnalytics>>, TError = ErrorType<HTTPValidationError>>(accountId: number,
    params: ReadAccountDailyTotalAnalyticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountDailyTotalAnalytics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAccountDailyTotalAnalyticsQueryKey(accountId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAccountDailyTotalAnalytics>>> = ({ signal }) => readAccountDailyTotalAnalytics(accountId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAccountDailyTotalAnalytics>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAccountDailyTotalAnalyticsQueryResult = NonNullable<Awaited<ReturnType<typeof readAccountDailyTotalAnalytics>>>
export type ReadAccountDailyTotalAnalyticsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Read Account Daily Total Analytics
 */
export const useReadAccountDailyTotalAnalytics = <TData = Awaited<ReturnType<typeof readAccountDailyTotalAnalytics>>, TError = ErrorType<HTTPValidationError>>(
 accountId: number,
    params: ReadAccountDailyTotalAnalyticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAccountDailyTotalAnalytics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAccountDailyTotalAnalyticsQueryOptions(accountId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Cf Bot Analytics
 */
export const getCfBotAnalytics = (
    botId: string,
    params: GetCfBotAnalyticsParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<CFBotAnalyticRead>(
      {url: `/cf-analytics/${botId}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetCfBotAnalyticsQueryKey = (botId: string,
    params: GetCfBotAnalyticsParams,) => {
    return ['cf-analytics',botId,...(params ? [params]: [])] as const;
    }

    
export const getGetCfBotAnalyticsQueryOptions = <TData = Awaited<ReturnType<typeof getCfBotAnalytics>>, TError = ErrorType<HTTPValidationError>>(botId: string,
    params: GetCfBotAnalyticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCfBotAnalytics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCfBotAnalyticsQueryKey(botId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCfBotAnalytics>>> = ({ signal }) => getCfBotAnalytics(botId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(botId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCfBotAnalytics>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCfBotAnalyticsQueryResult = NonNullable<Awaited<ReturnType<typeof getCfBotAnalytics>>>
export type GetCfBotAnalyticsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Cf Bot Analytics
 */
export const useGetCfBotAnalytics = <TData = Awaited<ReturnType<typeof getCfBotAnalytics>>, TError = ErrorType<HTTPValidationError>>(
 botId: string,
    params: GetCfBotAnalyticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCfBotAnalytics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCfBotAnalyticsQueryOptions(botId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Legacy Info
 */
export const getLegacyInfo = (
    
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<GetLegacyInfo200>(
      {url: `/legacy`, method: 'GET', signal
    },
      );
    }
  

export const getGetLegacyInfoQueryKey = () => {
    return ['legacy'] as const;
    }

    
export const getGetLegacyInfoQueryOptions = <TData = Awaited<ReturnType<typeof getLegacyInfo>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLegacyInfo>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLegacyInfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLegacyInfo>>> = ({ signal }) => getLegacyInfo(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLegacyInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLegacyInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getLegacyInfo>>>
export type GetLegacyInfoQueryError = ErrorType<unknown>

/**
 * @summary Get Legacy Info
 */
export const useGetLegacyInfo = <TData = Awaited<ReturnType<typeof getLegacyInfo>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLegacyInfo>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLegacyInfoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Bot Import
 */
export const botImport = (
    botImportParams: BodyType<BotImportParams>,
 ) => {
      
      
      return videobotFetch<BotImportRead>(
      {url: `/legacy/import`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: botImportParams
    },
      );
    }
  


export const getBotImportMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof botImport>>, TError,{data: BodyType<BotImportParams>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof botImport>>, TError,{data: BodyType<BotImportParams>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof botImport>>, {data: BodyType<BotImportParams>}> = (props) => {
          const {data} = props ?? {};

          return  botImport(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type BotImportMutationResult = NonNullable<Awaited<ReturnType<typeof botImport>>>
    export type BotImportMutationBody = BodyType<BotImportParams>
    export type BotImportMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Bot Import
 */
export const useBotImport = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof botImport>>, TError,{data: BodyType<BotImportParams>}, TContext>, }
) => {

      const mutationOptions = getBotImportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Linear Auth
 */
export const linearAuth = (
    accountId: number,
    linearAuth: BodyType<LinearAuth>,
 ) => {
      
      
      return videobotFetch<IntegrationRead>(
      {url: `/integration/linear/auth/account/${accountId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: linearAuth
    },
      );
    }
  


export const getLinearAuthMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof linearAuth>>, TError,{accountId: number;data: BodyType<LinearAuth>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof linearAuth>>, TError,{accountId: number;data: BodyType<LinearAuth>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof linearAuth>>, {accountId: number;data: BodyType<LinearAuth>}> = (props) => {
          const {accountId,data} = props ?? {};

          return  linearAuth(accountId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type LinearAuthMutationResult = NonNullable<Awaited<ReturnType<typeof linearAuth>>>
    export type LinearAuthMutationBody = BodyType<LinearAuth>
    export type LinearAuthMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Linear Auth
 */
export const useLinearAuth = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof linearAuth>>, TError,{accountId: number;data: BodyType<LinearAuth>}, TContext>, }
) => {

      const mutationOptions = getLinearAuthMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Hubspot Auth
 */
export const hubspotAuth = (
    accountId: number,
    hubspotAuth: BodyType<HubspotAuth>,
 ) => {
      
      
      return videobotFetch<IntegrationRead>(
      {url: `/integration/hubspot/auth/account/${accountId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: hubspotAuth
    },
      );
    }
  


export const getHubspotAuthMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof hubspotAuth>>, TError,{accountId: number;data: BodyType<HubspotAuth>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof hubspotAuth>>, TError,{accountId: number;data: BodyType<HubspotAuth>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof hubspotAuth>>, {accountId: number;data: BodyType<HubspotAuth>}> = (props) => {
          const {accountId,data} = props ?? {};

          return  hubspotAuth(accountId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type HubspotAuthMutationResult = NonNullable<Awaited<ReturnType<typeof hubspotAuth>>>
    export type HubspotAuthMutationBody = BodyType<HubspotAuth>
    export type HubspotAuthMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Hubspot Auth
 */
export const useHubspotAuth = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof hubspotAuth>>, TError,{accountId: number;data: BodyType<HubspotAuth>}, TContext>, }
) => {

      const mutationOptions = getHubspotAuthMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Feature Flags For Current User
 */
export const getFeatureFlagsForCurrentUser = (
    params?: GetFeatureFlagsForCurrentUserParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<string[]>(
      {url: `/feature`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetFeatureFlagsForCurrentUserQueryKey = (params?: GetFeatureFlagsForCurrentUserParams,) => {
    return ['feature',...(params ? [params]: [])] as const;
    }

    
export const getGetFeatureFlagsForCurrentUserQueryOptions = <TData = Awaited<ReturnType<typeof getFeatureFlagsForCurrentUser>>, TError = ErrorType<HTTPValidationError>>(params?: GetFeatureFlagsForCurrentUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFeatureFlagsForCurrentUser>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeatureFlagsForCurrentUserQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeatureFlagsForCurrentUser>>> = ({ signal }) => getFeatureFlagsForCurrentUser(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeatureFlagsForCurrentUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeatureFlagsForCurrentUserQueryResult = NonNullable<Awaited<ReturnType<typeof getFeatureFlagsForCurrentUser>>>
export type GetFeatureFlagsForCurrentUserQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Feature Flags For Current User
 */
export const useGetFeatureFlagsForCurrentUser = <TData = Awaited<ReturnType<typeof getFeatureFlagsForCurrentUser>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetFeatureFlagsForCurrentUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFeatureFlagsForCurrentUser>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFeatureFlagsForCurrentUserQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get All Feature Flags
 */
export const getAllFeatureFlags = (
    params?: GetAllFeatureFlagsParams,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<FlagSchema[]>(
      {url: `/feature/flags`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetAllFeatureFlagsQueryKey = (params?: GetAllFeatureFlagsParams,) => {
    return ['feature','flags',...(params ? [params]: [])] as const;
    }

    
export const getGetAllFeatureFlagsQueryOptions = <TData = Awaited<ReturnType<typeof getAllFeatureFlags>>, TError = ErrorType<HTTPValidationError>>(params?: GetAllFeatureFlagsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllFeatureFlags>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllFeatureFlagsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllFeatureFlags>>> = ({ signal }) => getAllFeatureFlags(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllFeatureFlags>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllFeatureFlagsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllFeatureFlags>>>
export type GetAllFeatureFlagsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get All Feature Flags
 */
export const useGetAllFeatureFlags = <TData = Awaited<ReturnType<typeof getAllFeatureFlags>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetAllFeatureFlagsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllFeatureFlags>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllFeatureFlagsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Flag
 */
export const createFlag = (
    flagSchema: BodyType<FlagSchema>,
 ) => {
      
      
      return videobotFetch<CreateFlag200>(
      {url: `/feature/flags`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: flagSchema
    },
      );
    }
  


export const getCreateFlagMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFlag>>, TError,{data: BodyType<FlagSchema>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createFlag>>, TError,{data: BodyType<FlagSchema>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFlag>>, {data: BodyType<FlagSchema>}> = (props) => {
          const {data} = props ?? {};

          return  createFlag(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateFlagMutationResult = NonNullable<Awaited<ReturnType<typeof createFlag>>>
    export type CreateFlagMutationBody = BodyType<FlagSchema>
    export type CreateFlagMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Flag
 */
export const useCreateFlag = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFlag>>, TError,{data: BodyType<FlagSchema>}, TContext>, }
) => {

      const mutationOptions = getCreateFlagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Feature
 */
export const updateFeature = (
    flagUpdate: BodyType<FlagUpdate>,
 ) => {
      
      
      return videobotFetch<UpdateFeature200>(
      {url: `/feature/flags`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: flagUpdate
    },
      );
    }
  


export const getUpdateFeatureMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFeature>>, TError,{data: BodyType<FlagUpdate>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateFeature>>, TError,{data: BodyType<FlagUpdate>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFeature>>, {data: BodyType<FlagUpdate>}> = (props) => {
          const {data} = props ?? {};

          return  updateFeature(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateFeatureMutationResult = NonNullable<Awaited<ReturnType<typeof updateFeature>>>
    export type UpdateFeatureMutationBody = BodyType<FlagUpdate>
    export type UpdateFeatureMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update Feature
 */
export const useUpdateFeature = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFeature>>, TError,{data: BodyType<FlagUpdate>}, TContext>, }
) => {

      const mutationOptions = getUpdateFeatureMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Feature Flags For Embed
 */
export const getFeatureFlagsForEmbed = (
    
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<string[]>(
      {url: `/feature/embed`, method: 'GET', signal
    },
      );
    }
  

export const getGetFeatureFlagsForEmbedQueryKey = () => {
    return ['feature','embed'] as const;
    }

    
export const getGetFeatureFlagsForEmbedQueryOptions = <TData = Awaited<ReturnType<typeof getFeatureFlagsForEmbed>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFeatureFlagsForEmbed>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeatureFlagsForEmbedQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeatureFlagsForEmbed>>> = ({ signal }) => getFeatureFlagsForEmbed(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeatureFlagsForEmbed>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFeatureFlagsForEmbedQueryResult = NonNullable<Awaited<ReturnType<typeof getFeatureFlagsForEmbed>>>
export type GetFeatureFlagsForEmbedQueryError = ErrorType<unknown>

/**
 * @summary Get Feature Flags For Embed
 */
export const useGetFeatureFlagsForEmbed = <TData = Awaited<ReturnType<typeof getFeatureFlagsForEmbed>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFeatureFlagsForEmbed>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFeatureFlagsForEmbedQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Return the configuration for any kind of public embed.

NOTE(2024-09-09): Added for Veikkaus usage in their mobile app,
and only currently supports "link" type embeds. Should be
expanded to any type of embed down the line.
 * @summary Get Embed By Id
 */
export const getEmbedById = (
    accountPublicId: string,
    embedPublicId: string,
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<PublicEmbed>(
      {url: `/v1/accounts/${accountPublicId}/embeds/${embedPublicId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetEmbedByIdQueryKey = (accountPublicId: string,
    embedPublicId: string,) => {
    return ['v1','accounts',accountPublicId,'embeds',embedPublicId] as const;
    }

    
export const getGetEmbedByIdQueryOptions = <TData = Awaited<ReturnType<typeof getEmbedById>>, TError = ErrorType<HTTPValidationError>>(accountPublicId: string,
    embedPublicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmbedById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEmbedByIdQueryKey(accountPublicId,embedPublicId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmbedById>>> = ({ signal }) => getEmbedById(accountPublicId,embedPublicId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(accountPublicId && embedPublicId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEmbedById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEmbedByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEmbedById>>>
export type GetEmbedByIdQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Embed By Id
 */
export const useGetEmbedById = <TData = Awaited<ReturnType<typeof getEmbedById>>, TError = ErrorType<HTTPValidationError>>(
 accountPublicId: string,
    embedPublicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmbedById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEmbedByIdQueryOptions(accountPublicId,embedPublicId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Health check
 * @summary Healthcheck
 */
export const healthcheck = (
    
 signal?: AbortSignal
) => {
      
      
      return videobotFetch<Healthcheck200>(
      {url: `/_/health`, method: 'GET', signal
    },
      );
    }
  

export const getHealthcheckQueryKey = () => {
    return ['_','health'] as const;
    }

    
export const getHealthcheckQueryOptions = <TData = Awaited<ReturnType<typeof healthcheck>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof healthcheck>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHealthcheckQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof healthcheck>>> = ({ signal }) => healthcheck(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof healthcheck>>, TError, TData> & { queryKey: QueryKey }
}

export type HealthcheckQueryResult = NonNullable<Awaited<ReturnType<typeof healthcheck>>>
export type HealthcheckQueryError = ErrorType<unknown>

/**
 * @summary Healthcheck
 */
export const useHealthcheck = <TData = Awaited<ReturnType<typeof healthcheck>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof healthcheck>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getHealthcheckQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




