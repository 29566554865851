import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import Loader from '@/components/Loader'
import media from '@/lib/media'
import { Cover } from '@/lib/mixins'
import { useReadBotWidgetById } from '@/api/videobot'

const VideobotIframeWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;

	iframe {
		display: block;
		border: 0;
		border-radius: 10px;
	}

	${(props) =>
		props.variant === 'exact' &&
		css`
			iframe {
				margin: auto;
				flex: none;
			}
		`}

	${(props) =>
		props.variant === 'cover' &&
		css`
			${Cover()}
			iframe {
				width: 100%;
				height: 100%;
				max-height: none !important;
				border-radius: 0;
			}
		`}

   ${(props) =>
		props.variant === 'resize' &&
		css`
			align-items: center;
			justify-content: center;

			iframe {
				height: 600px;
				width: 320px;
				${media('>lg')} {
					max-height: calc(90vh - 180px);
				}
				${media('<=lg')} {
					max-height: calc(90vh - ${(props) => props.theme.size.topBar.mobile});
				}
			}
		`}
`

export const VideobotIframe = ({ style, src, data, videobot, variant = 'resize' }) => {
	const iframeRef = useRef()
	const [loaded, setLoaded] = useState(false)
	const timer = useRef(null)
	const { data: embedData } = useReadBotWidgetById(videobot.id)

	useEffect(() => {
		const handleLoaded = () => {
			setLoaded(true)

			const addCustomStyles = (rawStyles, themeStyles, iframeDocument) => {
				let cssText = ''

				for (const selector in rawStyles) {
					const styleSet = rawStyles[selector]
					let rule = `${selector} {`
					for (const styleName in styleSet) {
						let value = styleSet[styleName]
						if (styleName === 'fontFamily' && value.includes(' ')) {
							value = `"${value}"`
						}
						rule += `${styleName}: ${value};`
					}
					rule += '}'
					cssText += rule
				}

				if (themeStyles) {
					let rootStyles = ':root {'
					if (themeStyles.backgroundColor) {
						rootStyles += `--color-primary: ${themeStyles.backgroundColor};`
					}
					if (themeStyles.textColor) {
						rootStyles += `--color-button-label: ${themeStyles.textColor};`
					}
					rootStyles += '}'
					cssText += rootStyles
				}

				// Adding styles to the main iframe
				const styleElement = iframeDocument.createElement('style')
				styleElement.type = 'text/css'
				styleElement.appendChild(iframeDocument.createTextNode(cssText))
				const head = iframeDocument.head || iframeDocument.getElementsByTagName('head')[0]
				head.appendChild(styleElement)

				// Check for child iframe and add styles only if found
				const childIframe = iframeDocument.querySelector('iframe')
				if (childIframe && childIframe.contentWindow.document) {
					const childIframeDocument = childIframe.contentWindow.document
					const styleElementChildIframe = childIframeDocument.createElement('style')
					styleElementChildIframe.appendChild(childIframeDocument.createTextNode(cssText))
					const headChildIframe =
						childIframeDocument.head ||
						childIframeDocument.getElementsByTagName('head')[0]
					headChildIframe.appendChild(styleElementChildIframe)
				}
			}

			const iframeDocument = iframeRef.current.contentWindow.document

			if (videobot && videobot.theme && videobot.theme.styles) {
				const rawStyles = videobot.theme.styles
				const themeStyles = {
					backgroundColor: videobot.theme.backgroundColor,
					textColor: videobot.theme.textColor,
				}

				if (Object.keys(rawStyles).length > 0 || Object.keys(themeStyles).length > 0) {
					addCustomStyles(rawStyles, themeStyles, iframeDocument)
				}
			}
		}

		timer.current = setInterval(() => {
			const iframeDocument =
				iframeRef.current?.contentDocument || iframeRef.current?.contentWindow?.document
			if (iframeDocument?.readyState === 'complete') {
				clearInterval(timer.current)
				handleLoaded()
			}
		}, 1000)

		let iframe = null

		if (iframeRef.current) {
			iframe = iframeRef.current
			iframeRef.current.addEventListener('load', handleLoaded)
		}

		return () => {
			clearInterval(timer.current)
			if (iframe) {
				iframe.removeEventListener('load', handleLoaded)
			}
		}
	}, [videobot])

	useEffect(() => {
		if (videobot && loaded) {
			const payload = {
				...videobot,
				companyProfile: embedData?.companyProfile,
				features: embedData?.features,
				widgetSettings: data,
			}
			// console.log('SET_DATA', payload)
			iframeRef.current.contentWindow.postMessage({
				action: 'SET_DATA',
				payload,
			})
		}
	}, [embedData, data, videobot, loaded])

	return (
		<VideobotIframeWrapper variant={variant}>
			{/* eslint-disable-next-line react-dom/no-missing-iframe-sandbox */}
			<iframe title="PREVIEW" ref={iframeRef} src={src} style={style} />
			{!loaded && <Loader noOverlay cover />}
		</VideobotIframeWrapper>
	)
}
